import React, {useState} from 'react'
import './home.css'

import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaRupeeSign,FaFolder,FaFolderOpen,FaFile,FaTools} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import './CSS/StockAudit.scss';
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";



function CostAudit() {
    const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    <Helmet>
      <title>Cost Audit</title>
      <meta name="description"  
      content="Meet our dedicated team of experts at SSCOIndia. Our professionals are skilled in providing financial management and strategic advice, tax and GST consulting, income tax filing, trademark registration, and NGO registration services." />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
          <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    <div>
      <h1 className='CostAuditHeading'>Cost Audit</h1>
      <p>
        Cost audit is a systematic examination of a company's cost accounting records, practices, and procedures to ensure they are accurate, reliable, and in compliance with applicable laws and regulations. The primary objective of cost audit is to identify any discrepancies, inefficiencies, or irregularities in the cost accounting system and provide recommendations for improvement.
      </p>
      <h2>Benefits of Cost Audit</h2>
      <ul>
        <li>Identifying cost-saving opportunities: Cost audit helps in identifying areas where cost savings can be achieved by streamlining processes, reducing wastage, and optimizing resource allocation.</li>
        <li>Ensuring compliance: Cost audit ensures that the company's cost accounting practices comply with legal and regulatory requirements.</li>
        <li>Enhancing transparency: By examining cost accounting records, cost audit enhances transparency and accountability in financial reporting.</li>
        <li>Improving decision-making: Accurate and reliable cost accounting information obtained through cost audit helps management make informed decisions regarding pricing, product mix, and resource allocation.</li>
        <li>Detecting fraud and irregularities: Cost audit helps in detecting any fraudulent activities, misappropriation of funds, or irregularities in the cost accounting system.</li>
      </ul>
      <h2>Process of Cost Audit</h2>
      <p>
        The process of cost audit typically involves the following steps:
      </p>
      <ol>
        <li>Planning and scoping: Defining the scope of the cost audit, determining the objectives, and planning the audit activities.</li>
        <li>Data collection: Gathering relevant cost accounting records, documents, and information for analysis.</li>
        <li>Data analysis: Analyzing the collected data to identify any discrepancies, inefficiencies, or irregularities.</li>
        <li>Findings and recommendations: Presenting the findings of the cost audit and providing recommendations for improvement.</li>
        <li>Reporting: Preparing a comprehensive cost audit report that summarizes the audit process, findings, and recommendations.</li>
        <li>Follow-up: Monitoring the implementation of recommended improvements and assessing their impact.</li>
      </ol>
      {/* Add additional sections and content as needed */}
    </div>
      
    
  
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default CostAudit