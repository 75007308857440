import React from 'react'
import './cardcontent.css'
import './home.css';
import './CSS/StockAudit.scss';
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaRupeeSign,FaFolder,FaFolderOpen,FaFile,FaTools} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import { useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";
function Central_excise() {
    const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    
    <Helmet>
      <title>Central Excise</title>
      <meta name="description" content="Understanding the concept and implications of central excise duty, its purpose, applicability, and impact on various stakeholders. Learn about the revenue generation and economic control aspects of central excise duty." />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
        <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    <div className="blog">
      <h1>Understanding Central Excise Duty</h1>
      <h3>Subtitle: Exploring an Indirect Tax for Government Revenue</h3>

        <div className="blog-content">
            <h2>Introduction</h2>
            <p>
            In the realm of fiscal policies and government revenue generation, central excise duty plays a crucial role. It is a form of indirect tax levied on goods manufactured or produced within the boundaries of a country. In this blog post, we will delve into the intricacies of central excise duty, its purpose, applicability, and its impact on various stakeholders.
            </p>

            <h2>What is Central Excise Duty?</h2>
            <p>
            Central Excise Duty, also known as CED, is a form of indirect tax imposed on the manufacture or production of goods within a country. It is governed by the Central Excise Act, 1944 (now subsumed under the Goods and Services Tax Act, 2017 in India). The primary objective of levying excise duty is to generate revenue for the government and regulate the production and consumption of goods.
            </p>

            <h2>Purpose of Central Excise Duty</h2>
            <p>
            <ol>
                <li>Revenue Generation: Central excise duty is a significant source of revenue for the government. It helps in financing various developmental activities and public welfare programs.</li>
                <li>Economic Control: By imposing excise duty, the government can regulate the production and consumption of goods. It enables them to influence the demand and supply dynamics, manage inflation, and encourage or discourage the production of certain goods.</li>
            </ol>
            </p>

            <h2>Applicability and Calculation</h2>
            <p>
            Central excise duty is typically levied at the time of manufacturing or production of goods. It is imposed on the manufacturer or producer of goods and is eventually passed on to the end consumer as part of the product's price.
            </p>
            <p>
            The calculation of excise duty is usually based on the value or quantity of goods produced. The duty is generally charged as a percentage of the assessable value of the goods. However, the specific method of calculation may vary from country to country.
            </p>

            <h2>Impact on Stakeholders</h2>
            <p>
            <ol>
                <li>Manufacturers and Producers: Central excise duty directly affects manufacturers and producers as they are liable to pay the duty on their manufactured goods. It adds to their production costs, which may be passed on to consumers through increased prices. Compliance with excise regulations and documentation is crucial for manufacturers to avoid penalties and legal issues.</li>
                <li>Consumers: The burden of central excise duty indirectly affects consumers. Increased excise duty can lead to higher prices of goods, reducing the purchasing power of consumers. In some cases, the impact may be more significant for essential commodities, which can disproportionately affect lower-income individuals and families.</li>
                <li>Government: Central excise duty serves as a significant source of revenue for the government. It plays a vital role in funding public infrastructure, social welfare programs, and government expenditure. The government can also utilize excise duty as an economic tool to control the production and consumption patterns of goods.</li>
            </ol>
            </p>

            <h2>Conclusion</h2>
            <p>
            Central excise duty plays a pivotal role in the economic landscape of a country. It serves as a vital source of revenue for the government while enabling the regulation of manufacturing and consumption patterns. Understanding the implications of excise duty helps stakeholders, including manufacturers, consumers, and the government, make informed decisions and navigate the complexities of the taxation system.
            </p>
            <p>
            While this blog post provides a broad overview of central excise duty, it is essential to consult the specific laws and regulations of your country for a comprehensive understanding of its applicability and impact.
            </p>
        </div>
    </div>
  
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default Central_excise