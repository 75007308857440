import React, {useState} from 'react'
import './home.css'

import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaRupeeSign,FaFolder,FaFolderOpen,FaFile,FaTools} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import './CSS/StockAudit.scss';
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";


function ComplianceReport() {
    const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    
    <Helmet>
      <title>Compliance Report</title>
      <meta name="description" content="Exploring the significance of compliance reports in ensuring ethical and legal business practices. Understand the purpose of compliance reports, their benefits, and how they contribute to enhancing transparency and accountability within organizations." />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
          <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    <div className="blog">
      <h1>The Importance of Compliance Reports: Ensuring Ethical and Legal Business Practices</h1>
      <h3>Subtitle: A Comprehensive Overview</h3>
  <div className="blog-content">
    <h2>Introduction</h2>
    <p>
      In the corporate world, maintaining compliance with laws, regulations, and ethical standards is of utmost importance. Compliance reports play a crucial role in ensuring that companies adhere to these requirements and conduct their business in a responsible and lawful manner. In this blog post, we will explore the significance of compliance reports, their purpose, and how they contribute to the overall success and reputation of an organization.
    </p>

    <h2>Understanding Compliance Reports</h2>
    <p>
      A compliance report is a comprehensive document that outlines a company's adherence to relevant laws, regulations, and internal policies. It provides an overview of the company's compliance efforts, highlights any instances of non-compliance, and presents strategies for remediation and improvement.
    </p>

    <h2>The Purpose of Compliance Reports</h2>
    <p>
      <ol>
        <li>Evidence of Compliance: Compliance reports serve as tangible evidence that a company has followed all applicable laws, regulations, and internal policies. They provide a record of the company's commitment to conducting business ethically and responsibly.</li>
        <li>Identification of Non-Compliance: Compliance reports help identify instances of non-compliance within the organization. By thoroughly reviewing and assessing business practices, policies, and procedures, companies can uncover any areas where they may be falling short and take corrective measures.</li>
        <li>Risk Mitigation: Compliance reports assist in mitigating legal, financial, and reputational risks. By proactively identifying and addressing compliance gaps, companies can reduce the likelihood of penalties, lawsuits, or damage to their reputation.</li>
        <li>Transparency and Accountability: Publishing compliance reports demonstrates a company's commitment to transparency and accountability. It allows stakeholders, including investors, customers, and employees, to assess the company's ethical standing and make informed decisions.</li>
        <li>Continuous Improvement: Compliance reports serve as a foundation for ongoing improvement and enhancement of compliance programs. They enable companies to analyze trends, identify recurring issues, and implement corrective actions to strengthen their compliance framework.</li>
      </ol>
    </p>

    <h2>Key Components of a Compliance Report</h2>
    <p>
      While the specific contents of a compliance report may vary depending on the industry and regulatory requirements, some common components include:
      <ul>
        <li>Executive Summary: A concise overview of the report, highlighting key findings and recommendations.</li>
        <li>Scope and Methodology: An explanation of the scope of the compliance assessment and the methods used to gather data and evaluate compliance.</li>
        <li>Compliance Findings: A detailed analysis of compliance performance, including areas of strength and weakness, instances of non-compliance, and any regulatory or legal violations.</li>
        <li>Remediation Actions: Proposed strategies and action plans to address non-compliance issues and prevent future occurrences.</li>
        <li>Compliance Program Evaluation: An assessment of the effectiveness of the company's compliance program, including policies, training, monitoring, and reporting mechanisms.</li>
        <li>Conclusion and Recommendations: A summary of the overall compliance status, key takeaways, and recommended steps for improvement.</li>
      </ul>
    </p>

    <h2>Conclusion</h2>
    <p>
      Compliance reports are an essential tool for companies to demonstrate their commitment to ethical and legal business practices. They provide a comprehensive overview of a company's compliance efforts, identify areas of improvement, and help mitigate risks. By prioritizing compliance and regularly assessing their adherence to regulations, companies can build trust, protect their reputation, and contribute to a more ethical and sustainable business environment.
    </p>
  </div>
</div>
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default ComplianceReport