import React, {useState} from 'react'
import './Contact.css'
import './home.css'
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaHome} from 'react-icons/fa'; 
import { MdMail,MdPhoneAndroid,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import {Helmet} from "react-helmet";
function Contact() {
  const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    <Helmet>
      <title>Contact to SSCOINDIA</title>
      <meta name="description"  
      content="Get in touch with our team at SSCOIndia. Contact us for expert financial management and strategic advice, tax and GST consulting, income tax filing, trademark registration, and NGO registration services."/>
    </Helmet>
     <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
        <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    {/* Map Section */}
      <div className="contact">
       <div className="map">
        <iframe className='iframe'title='map' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=NO-305,%203RD,%20Vikas%20Marg,%20Shakarpur%20+(S.SHEKHAR%20&amp;%20CO.,%20Cost%20Accountants,%20School%20Block,%20S-524,%20NEELKANTH%20HOUSE,%20OFFICE%20NO-305,%203RD,%20Vikas%20Marg,%20Shakarpur,%20Delhi,%20110092)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
       </div>
       <div className="contact-info">
        <div className="info">
         <div className="address">
           <h2><FaHome/></h2>
           <p>S-524 - Neelkanth House, Office No.202- 2nd Floor, School Block, Vikas Marg, Shakarpur, Delhi-110092</p>
         </div>
         <div className="phone">
           <h2><MdPhoneAndroid/></h2>
           <p>+91 8622086220</p>
           <p>+91 1141037186</p>
         </div>
         <div className="mail-contact">
           <h2><MdMail/></h2>
           <p>sudhanshu.ssco@gmail.com</p>
           <p>sudhanshu@sscoindia.com</p>
         </div>
        </div>
       </div>
      </div>
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About">About Us</a> </li>
              <li><a href="/Contact"> Contact Us</a></li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
      
    </>
  )
}

export default Contact