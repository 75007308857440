import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import About from './ssco/About';
import Services from './ssco/Services';
import Team from './ssco/Team'
import Client from './ssco/Client'
import Career from './ssco/Career'
import Contact from './ssco/Contact'
import CostAudit from './ssco/CostAudit'
import StockAudit from './ssco/StockAudit';
import Central_excise from './ssco/Central_excise';
import ComplianceReport from './ssco/ComplianceReport';
import CostAccountingRecords from './ssco/CostAccountingRecords';
// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <Router>
    <Routes>
       <Route path='/' element={<App/>}/>
       <Route path='/About' element={<About/>}/>
       <Route path='/Services' element={<Services/>}/>
       <Route path='/Team' element={<Team/>}/>
       <Route path='/Client' element={<Client/>}/>
       <Route path='/Career' element={<Career/>}/>
       <Route path='/Team' element={<Team/>}/>
       <Route path='/Contact' element={<Contact/>}/>
       <Route path='/CostAudit' element={<CostAudit/>}/>
       <Route path='/StockAudit' element={<StockAudit/>}/>
       <Route path='/CentralExcise' element={<Central_excise/>}/>
       <Route path='/Compliance' element={<ComplianceReport/>}/>
       <Route path='/Maintenance' element={<CostAccountingRecords/>}/>
    </Routes>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
