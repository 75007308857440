import React from 'react'
import './home.css'
function form() {
    
  return (
    <div className="form-footer">
    <form  action='https://formspree.io/f/moqzqqyl' method='POST'>
      <h2>CONSULTATION</h2>
     <div>
       <input name='name' id="name" type="text"placeholder="Your name" required autoComplete="off"/>
     </div>
     <div>
       <input name='email' id="email" type="email" placeholder="Your Email" required autoComplete="off"/>
     </div>
     <div>
       <input name='phone' id="phone" type="phone" placeholder="Your Phone" required autoComplete="off"/>
     </div>
     <div>
       <textarea name='subject' id="subject"placeholder="Subject" required/>
      </div>
      <div>
       <textarea name='message' id="message"placeholder="Message" required/>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
  )
}

export default form