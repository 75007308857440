import React, {useState} from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import CarouselElement from './Carousel.jsx';
// import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import  Avtar  from './srcimg/Avtar.png'
import  Sudhanshu  from './srcimg/Sudhanshu.png'
import Form from './form.jsx'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import aboutimg from "./about-us-removebg-preview.png"
import HeaderImg from "./headerimg.jpg"
import {Helmet} from "react-helmet";
function Team() {
  const [showNavelinks,setShowNavelinks]= useState(true);
 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
    <Helmet>
      <title>Team SSCOINDIA</title>
      <meta name="description"  
      content="Meet our dedicated team of experts at SSCOIndia. Our professionals are skilled in providing financial management and strategic advice, tax and GST consulting, income tax filing, trademark registration, and NGO registration services." />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
          <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    {/* <div className="imgh">
      <div className="imgHeader">
        <img src={HeaderImg} alt="" />
      </div>
    </div> */}
    {/* Team Section */}
      
    <div className="Team-SSCO">
      <div className="Team-header">
        {/* <h2>Who We Are? Meet Our Team!</h2> */}
        <h2>Welcome to the SSCO India & Co team page!</h2>
        <p>
           Our team is made up of talented individuals who are passionate about providing exceptional service to our
           clients. We are a group of professionals with diverse backgrounds and expertise, ranging from accounting 
           and finance to law and taxation. At SSCO India & Co, we value teamwork, collaboration, and innovation. We believe that the success of our 
           clients is our success, and we work tirelessly to ensure that our clients receive the best possible service.
           We are committed to delivering high-quality work, meeting deadlines, and exceeding expectations.Our team members 
           bring a wealth of experience and knowledge to the table, and we believe in sharing that knowledge to help our clients succeed.
           We are constantly learning and adapting to changes in our industry, so that we can stay ahead of the curve and provide the best 
           possible solutions to our clients. We take pride in our work and strive to build lasting relationships with our clients. 
           Our team is dedicated to providing personalized service and attention to each and every client, no matter the size or complexity of their needs.
           We invite you to learn more about our team members and their areas of expertise. If you have any questions or would like to discuss 
           how we can help you achieve your business goals, please don't hesitate to contact us.
        </p>
      </div>
      {/* <Carousel autoPlay interval="5000"transitionTime="5000" showThumbs={false} activeIndex="1" showStatus={false} > */}
      {/* <div className="Team-Carousel" >
        <CarouselElement
         img={Avtar}
         name="CMA Sudhanshu Sekhar"
         place="New Delhi"
         About='Sudhanshu enrolled as a member of the Institute of Cost Accountants of India in Feb-2011 and
          started self practice since then. Sudhanshu has vast experience in the field of Internal Audit, 
          Direct & Indirect Taxation, Cost Audit and Stock Audit with practical knowledge of generally accepted 
          cost accounting principles and cost accounting standards.'
        />
        <CarouselElement
         img={Avtar}
         name="CMA Dipak Lal"
         place="Kolkata"
         About='Dipak enrolled as a member of the Institute of the Cost Accountants of India in Oct-2009 and started 
         practice from Jan 12. Dipak has worked in corporate sectors more then 5yrs and has vast experience in Taxation, 
         Cost Audit, VAT Audit etc. He has recently completed lots of Cost Compliances and Audits.'
        />
        <CarouselElement
          img={Avtar}
         name="CMA Srikant"
         place="Ranchi"
         About='Srikant enrolled as a member of the Institute of Cost Accountants of India in Jan-2010 and started 
          practice from Aug 12 after giving his services to an MNC and an Indian Company for more than 3yrs.
          During this period he acquired expertise in Management Reporting, Receivable Management, 
          Product Costing and other areas. Srikant has rich experience in the field of Cost Audit and Maintenance 
          of Cost Records. He has recently undertaken a vast range of assignments in the areas of Cost Audit and
          Cost Compliance.'
        />
        
           </div> */}
        {/* <div className="Team-Carousel">
        <CarouselElement
         img={Avtar}
         name="CMA Ashish Kumar Srivastava"
         place="New Delhi"
         About='Ashish enrolled as a member of the Institute of the Cost Accountants of India in August 2014
          and started practice since then. He has good experience in Cost Audit, Maintaining Cost Records and other 
          costing area.He has recently undertaken a vast range of assignments in the areas of Cost Audit and Cost Compliance.'
        />
        <CarouselElement
         img={Avtar}
         name="CMA Anima Sweta"
         place="Jharkhand"
         About='Mrs. Anima Sweta enrolled as a member of the Institute of the Cost Accountants of India in Dec-2010 and 
         started practice from june 2018 since then, Anima has worked in mannufacturing sector more than six years as a 
         Manager-costing & MIS and has a vast exprience in costing. Budgeting, MIS reportinng, inancial Management as well as Supply Chain Operation.'
        />
        <CarouselElement
         img={Avtar}
         name="CMA Rajendra Kumar"
         place="New Delhi"
         About='Rajendra enrolled as a member of the Institute of the Cost Accountants of India in June 2018 and 
         started practice since then. He has worked in corporate sector in more than 8 Years and has vast exprience 
         in Taxation, Cost Audit etc.. He has recently completed lots of cost compliance and Audit.'
        />  
        </div> */}
        
      {/* </Carousel> */}
      <Carousel responsive={responsive}>
      <div className="Team-Carousel">
       <CarouselElement
         img={Sudhanshu}
         name="CMA Sudhanshu Sekhar"
         achievement="FCMA, M.Com, DISSA- (ICAI), INDEPENDENT DIRECTOR (MCA)"
         place="New Delhi"
         About='Sudhanshu enrolled as a member of the Institute of Cost Accountants of India in Feb-2011 and
          started self practice since then. Sudhanshu has vast experience in the field of Internal Audit, 
          Direct & Indirect Taxation, Cost Audit and Stock Audit with practical knowledge of generally accepted 
          cost accounting principles and cost accounting standards.'
        />
        </div>
        <div className="Team-Carousel">
         <CarouselElement
         img={Avtar}
         name="CMA Dipak Lal"
         place="Kolkata"
         About='Dipak enrolled as a member of the Institute of the Cost Accountants of India in Oct-2009 and started 
         practice from Jan 12. Dipak has worked in corporate sectors more then 5yrs and has vast experience in Taxation, 
         Cost Audit, VAT Audit etc. He has recently completed lots of Cost Compliances and Audits.'
        />
        </div>
        <div className="Team-Carousel">
         <CarouselElement
          img={Avtar}
         name="CMA Srikant"
         place="Ranchi"
         About='Srikant enrolled as a member of the Institute of Cost Accountants of India in Jan-2010 and started 
          practice from Aug 12 after giving his services to an MNC and an Indian Company for more than 3yrs.
          During this period he acquired expertise in Management Reporting, Receivable Management, 
          Product Costing and other areas. Srikant has rich experience in the field of Cost Audit and Maintenance 
          of Cost Records. He has recently undertaken a vast range of assignments in the areas of Cost Audit and
          Cost Compliance.'
        />
        </div>
        <div className="Team-Carousel">
        <CarouselElement
         img={Avtar}
         name="CMA Ashish Kumar Srivastava"
         place="New Delhi"
         About='Ashish enrolled as a member of the Institute of the Cost Accountants of India in August 2014
          and started practice since then. He has good experience in Cost Audit, Maintaining Cost Records and other 
          costing area.He has recently undertaken a vast range of assignments in the areas of Cost Audit and Cost Compliance.'
        />
        </div>
        <div className="Team-Carousel">
        <CarouselElement
         img={Avtar}
         name="CMA Anima Sweta"
         place="Jharkhand"
         About='Mrs. Anima Sweta enrolled as a member of the Institute of the Cost Accountants of India in Dec-2010 and 
         started practice from june 2018 since then, Anima has worked in mannufacturing sector more than six years as a 
         Manager-costing & MIS and has a vast exprience in costing. Budgeting, MIS reportinng, inancial Management as well as Supply Chain Operation.'
        />
        </div>
        <div className="Team-Carousel" >
         <CarouselElement
         img={Avtar}
         name="CMA Rajendra Kumar"
         place="New Delhi"
         About='Rajendra enrolled as a member of the Institute of the Cost Accountants of India in June 2018 and 
         started practice since then. He has worked in corporate sector in more than 8 Years and has vast exprience 
         in Taxation, Cost Audit etc.. He has recently completed lots of cost compliance and Audit.'
        />   
       </div>
      </Carousel>
     </div>  
  
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default Team