import React, {useState} from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaRupeeSign,FaFolder,FaFolderOpen,FaFile,FaTools} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import HeaderImg from "./headerimg.jpg"
import Mycard from './Mycard.jsx'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";
// import CostAudit from './CostAudit';
var icon=<FaRupeeSign/>
var icon1=<FaFolder/>
var icon2=<FaFolderOpen/>
var icon3=<FaFile/>
var icon4=<FaTools/>
function Services() {
  const [showNavelinks,setShowNavelinks]= useState(true);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
    <Helmet>
      <title>Services provided by SSCOINDIA</title>
      <meta name="description"  
      content="Discover our comprehensive range of services at SSCOIndia. We specialize in financial management, strategic advice, tax advisory, GST consultation, income tax filing, trademark registration, and NGO registration." />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
           <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <Link to='/Services'>Services</Link>
                {/* <a href="/Services">Services</a> */}
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="#Help">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    {/* <div className="imgh">
      <div className="imgHeader">
        <img src={HeaderImg} alt="" />
      </div>
    </div> */}
    {/* About Section */}
    {/* <div className="About-us">
      <div className="para">
        <h4>WELCOME TO ACCOUNTING</h4>
        <h1>S.Shekhar & Co.</h1>
        <h3>Cost Accountants</h3>
        <p>S.Shekhar & Co. based in New Delhi is a cost accountancy firm, 
          providing services relating to cost audit, cost compliance, maintenance of cost records,
          direct and indirect taxation, management and business consultancy, valuation & certification.
        </p>
        <p>The Company has a proven history supplying prestigious and influential companies with services
           relating to cost audit that takes advantage of excellent staffing of the company in this field. 
           The firm provides access to the expertise and experience of highly qualified professionals who have 
           specialization in different areas of work.
        </p>
        <p>
          S.Shekhar & Co is now an established and fast-growing independent player in it's field having it's HO
           in New Delhi and branch offices in Ranchi and Kolkata. S.Shekhar & Co continues to expand its offerings 
           in Cost audit services and direct and indirect taxation in all over India.
        </p>
      </div>
      <div className="about-img">
        <img src={aboutimg} alt="" />
      </div>
    </div> */}
    {/* Services Section */}
    <div className="services">
      <div className="services-info">
        <div className="services-header">
          <h1> <span>Our Services</span></h1>
          <h1>What Service We Offer</h1>
        </div>
        {/* <div className="services-btn">
          <button type='submit'>ALL Services</button>
        </div> */}
      </div>
    
      <Carousel responsive={responsive}>
        <div className="services-carousel">
         <Mycard 
          href="/CostAudit"
          icon={icon}
          Servicename="Cost Audit"
          Description=""     
         />  
        </div>
        <div className="services-carousel">
         <Mycard 
           icon={icon1}
           href="/StockAudit"
           Servicename="Stock Audit and Concurrent
           Audit for Bank"
           Description=""
         /> 
        </div>
        <div className="services-carousel">
         <Mycard 
          href="/CentralExcise"
           icon={icon2} 
           Servicename="Central Excise Audit"
           Description=""
         />
        </div>
        <div className="services-carousel">
         <Mycard 
           href="/Compliance"
           icon={icon3}
           Servicename="Compliance Report as required
           by the Companies"
           Description=""
         />
        </div>
        <div className="services-carousel">
         <Mycard 
           href="/Maintenance"
           icon={icon4} 
           Servicename="Maintenance of Cost Accounting
           Records"
           Description=""
         />
        </div>
        </Carousel>
    </div>
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a></li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default Services