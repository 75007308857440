import React, {useState} from 'react'
import './home.css'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import aboutimg from "./about-us-removebg-preview.png"
import HeaderImg from "./headerimg.jpg"
import Form from './form.jsx'
import {Helmet} from "react-helmet";
function About() {
  const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    <Helmet>
      <title>About SSCOINDIA</title>
      <meta name="description"  
      content="Learn about SSCOIndia - a leading CMA firm in Delhi, offering financial management and strategic advice to businesses. Our services include tax advisory, GST consultation, income tax filing, trademark and NGO registration." />
    </Helmet>
      <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
          <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                <a href="/">Home</a>
            </li>
            <li>
                <a href="/About">About Us</a>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    <div className="imgh">
      <div className="imgHeader">
        <img src={HeaderImg} alt="SSCO Header" />
      </div>
    </div>
    {/* About Section */}
    <div className="About-us">
      <div className="para">
        <h1>WELCOME TO SSCOINDIA</h1>
        <h2>Leading accountency firm in delhi </h2>
        <h1>S.Shekhar & Co.</h1>
        <h3>Cost Accountants</h3>
        <p>S.Shekhar & Co. is a reputable cost accountancy firm based in New Delhi that offers a wide range of financial services. 
          The company specializes in cost audit, cost compliance, maintenance of cost records, direct and indirect taxation,
           management and business consultancy, valuation, and certification.
        </p>
        <p>The firm has a proven track record of providing top-notch services to prestigious and influential companies in India.
           The team at S.Shekhar & Co. consists of highly qualified and experienced professionals with expertise in different areas of work, 
          providing clients with access to a wide range of skills and knowledge.
        </p>
        <p>
        S.Shekhar & Co. has established itself as a fast-growing player in the cost audit and taxation fields.
         With its head office in New Delhi and branch offices in Ranchi and Kolkata, 
        the company is now expanding its offerings in cost audit services and direct and indirect taxation across India.
        </p>
        <p>At S.Shekhar & Co., the focus is always on providing clients with the highest quality services and customized 
          solutions to meet their unique needs. With a commitment to excellence and customer satisfaction,
           S.Shekhar & Co. is the trusted partner for all your cost accountancy needs.
        </p>
      </div>
      <div className="about-img">
        <img src={aboutimg} alt="" />
      </div>
    </div>
    
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About">About Us</a> </li>
              <li><a href="/Contact"> Contact Us</a></li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default About