import React, {useState} from 'react'
import './Client.css'
import './home.css'
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaHandPointRight} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import {Helmet} from "react-helmet";
function Contact() {
  const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    <Helmet>
      <title>Client of SSCOINDIA</title>
      <meta name="description"  
      content="Explore our satisfied clients' success stories at SSCOIndia. 
      We have helped numerous businesses achieve their financial goals with our expert
       financial management and strategic advice, tax and GST consulting,
       income tax filing,trademark registration, and NGO registration services." />
    </Helmet>
     <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
          <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    
    {/* Client Section */}
     <div className="Client">
      <div className="Clientheading">
        <h2>OUR CLIENTS</h2>
        <span>Based on Product Group Code Wise:</span>
      </div>
      <div className="client-main">
      <div className="client1">
        <p id="para"><FaHandPointRight/>(2008) Electrical Energy</p>
        <p id="para"><FaHandPointRight/>(1013) Tea &amp; Tea products</p>
        <p id="para"><FaHandPointRight/>(1015) Cereals,Flour &amp; Products of cereals</p>
        <p id="para"><FaHandPointRight/>(1024) Prepared Foods Products </p>
        <p id="para"><FaHandPointRight/>(2002) Cement</p>
        <p id="para"><FaHandPointRight/>(2038) Rubber Tyres &amp; Tubes</p>
        <p id="para"><FaHandPointRight/>(3002) Leather Products</p>
        <p id="para"><FaHandPointRight/>(3018) Cotton</p>
        <p id="para"><FaHandPointRight/>(4002) Iron and Non-Alloy Steel</p>
        <p id="para"><FaHandPointRight/>(4005) Steel Products</p>
        <p id="para"><FaHandPointRight/>(5001) Construction of Residential Building</p>
      </div>
      <div className="client2">
        <p id="para"><FaHandPointRight/>(5002) Construction of Non-Residential Building</p>
        <p id="para"><FaHandPointRight/>(5003) Construction of highway, Roads, Rails, Bridges, etc.</p>
        <p id="para"><FaHandPointRight/>(2034) Insecticides</p>
        <p id="para"><FaHandPointRight/>(2020) Mineral or Chemical Fertilizers –Others</p>
        <p id="para"><FaHandPointRight/>(2001) Mineral Products</p>
        <p id="para"><FaHandPointRight/>(2003) Mineral Fuels (other than petroleum)</p>
        <p id="para"><FaHandPointRight/>(4017) Machinery &amp; Mechanical Appliances</p>
        <p id="para"><FaHandPointRight/>(4027) Parts &amp; Accessories of Vehicles</p>
        <p id="para"><FaHandPointRight/>(4016) Engine Motors &amp; Parts thereof</p>
        <p id="para"><FaHandPointRight/>(2015) Pharmaceuticals Products</p>
        <p id="para"><FaHandPointRight/>(2016) Animal or Vegetable Fertilizers</p>
        <p id="para"><FaHandPointRight/>(9015) Telecommunication</p>
      </div>
      </div>
     </div>
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
      
    </>
  )
}

export default Contact