import React from 'react'
import './cardcontent.css'
import './home.css';
import './CSS/StockAudit.scss';
import { Link } from 'react-router-dom'
import {  FaLinkedin, FaPhone, FaTwitter,FaFacebook,FaRupeeSign,FaFolder,FaFolderOpen,FaFile,FaTools} from 'react-icons/fa'; 
import { MdMail,MdMenu} from "react-icons/md";
import CMA from "./CMAlogo.jpg"
import Form from './form.jsx'
import { useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";
function StockAudit() {
  const [showNavelinks,setShowNavelinks]= useState(true);
  return (
    <>
    
    <Helmet>
      <title>Stock Audit</title>
      <meta name="description"  
      content="Stock audit information and services" />
    </Helmet>
    <div className="header">
      <div className="container">
        <div className="row">
            <div className="row1">
              <div className="contactinfo">
               <span className='phonenum'><FaPhone/><p>+91-8622086220</p></span>           
               <span className='mail'><MdMail/><p className="email">sudhanshu.ssco@gmail.com</p></span>
              </div>
            </div>
            <div className="row2">
               <div className="socialmedia">
                <p className="sm">
                    <a href="http://"><span className='facebook'><FaFacebook/></span></a>
                    <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
                    <a href="http://"><span className="twitter"><FaTwitter/></span></a>
                </p>
               </div>
            </div>
        </div>
      </div>
       {/* Navbar Start */}
       <div className="navbar">
        <div className="ssco">
        <div className="sscologo">
            <a href='/'><img src={CMA} alt="SSCO" /></a>
            <div className="companyname">
             <h2>S.SHEKHAR & Co.</h2>
             <h3>Cost Accounts</h3>
             <p>Delivering quality services on time is our prime motive!</p>
             
            </div>
          </div>
          {/* <div className="ssconame"></div> */}
        </div>
        <div className="menuIcon">
           <h2><a href="#" onClick={()=>setShowNavelinks(!showNavelinks)}><MdMenu/></a></h2>      
        </div>
        <div className={(showNavelinks ? "hidden" : "")+ " Nav-Links"}> 
         <ul>
            <li>
                {/* <a href="#home">Home</a> */}
                <Link to='/'>Home</Link>
            </li>
            <li>
                {/* <a href={<About/>}>About Us</a> */}
                <Link to='/About'>About Us</Link>
            </li>
            <li>
                <a href="/Services">Services</a>
            </li>
            <li>
                <a href="/Team">Our Team</a>
            </li>
            <li>
                <a href="/Client">Our Clients</a>
            </li>
            <li>
                <a href="/Career">Career</a>
            </li>
            <li>
                <a href="/Contact">Contact Us</a>
            </li>
         </ul>         
       </div>
       </div>
    </div>
    <div className="blog">
      <h1>Understanding Stock Audit and Concurrent Audit</h1>
      <h3>Subtitle: Enhancing Financial Accountability and Transparency</h3>

      <div className="blog-content">
        <h2>Introduction</h2>
        <p>
         In today's fast-paced and complex business landscape, it is crucial for organizations to maintain accurate records of their stock and financial transactions.
         This not only ensures operational efficiency but also fosters transparency and trust among stakeholders. To achieve this, businesses often rely on two types of audits - Stock Audit and Concurrent Audit. 
         In this blog post, we will delve into the concept of these audits, explore their significance,
         and understand how they contribute to bolstering financial accountability.{/* Content continues */}
        </p>

        <h2>Stock Audit: A Closer Look</h2>
        <p>
          Stock Audit, also known as Inventory Audit, involves the physical verification of a company's stock. 
          The primary objective of this audit is to verify the accuracy and existence of physical stock by reconciling it with the recorded data. 
          Stock Audit is usually performed by external auditors who conduct a thorough inspection of the inventory, 
          storage facilities, and related records.The audit process includes counting, valuation, and reporting on discrepancies, if any.
        </p>

        <h2>Key Benefits of Stock Audit</h2>
        <ul>
          <li>Accurate Stock Valuation: <p> By conducting regular stock audits, organizations can determine the true value of their inventory. 
            This helps in preventing overvaluation or undervaluation, ensuring financial statements accurately represent the company's financial position.</p></li>
          <li>Identifying Pilferage and Fraud: <p>Stock audits help uncover instances of pilferage, theft, or fraudulent activities within the organization. By identifying and addressing such issues promptly, businesses can mitigate risks and safeguard their assets.</p></li>
          <li>Improved Operational Efficiency<p>Stock audits provide insights into inventory management practices, highlighting areas of improvement. This enables businesses to streamline processes, optimize stock levels, and reduce carrying costs.</p></li>
        </ul>
        <h2>Introduction</h2>
        <h2>Concurrent Audit: A Closer Look</h2>
        <p>
           Concurrent Audit: Enhancing Financial Controls Concurrent Audit, also known as Real-Time Audit,
            is an ongoing review of financial transactions conducted concurrently with the occurrence of transactions.
             Unlike traditional audits that are performed periodically, concurrent audits provide real-time monitoring and evaluation of financial operations.
        </p>

        <h2>Key Benefits of Concurrent Audit</h2>
        <ul>
          <li>Fraud Prevention and Detection: <p> By conducting continuous audits, concurrent auditors can identify potential fraud or irregularities as they happen. This helps in taking immediate corrective actions and prevents financial losses.</p></li>
          <li>Compliance and Risk Management: <p>Concurrent audits ensure adherence to regulatory and internal control requirements. By monitoring transactions in real-time, organizations can identify risks, prevent non-compliance, and strengthen governance frameworks.</p></li>
          <li>Operational Efficiency:<p>Concurrent audits help identify process inefficiencies, bottlenecks, and control gaps. This enables businesses to make timely improvements, enhance efficiency, and reduce operational risks.</p></li>
        </ul>
        {/* Content continues */}
      </div>
    </div>
  
    {/* Footer Section */}
    <div className="footer">
      <div className="row-footer">
       <div className="allinfo">
          <div className="about-footer">
            <div className="parafoot">
              <h2>ABOUT US</h2>
              <p>The Company has a proven history supplying prestigious and influential 
                companies with services relating to cost audit that takes advantage of excellent 
                staffing of the company in this field.
              </p>
            </div>
            <div className="socialiconsfoot">
               <a href="http://"><span className='facebook'><FaFacebook/></span></a>
               <a href="http://"><span className='linkedin'><FaLinkedin/></span></a>
               <a href="http://"><span className="twitter"><FaTwitter/></span></a>
            </div>
          </div>
          <div className="services-footer">
             <h2>SERVICES</h2>
             <p>Cost Audit</p>
             <p>Stock Audit and Concurrent Audit for the Bank</p>
             <p>Central Excise Audit</p>
             <p>Compliance Report as required by the Companies</p>
             <p>Maintenance of Cost Accounting Records</p>
             <p>Design and Implimentation of Cost Accounting System</p>
          </div>
          <div className="discover-footer">
             <h2>DISCOVER</h2>
             <ul>
              <li><a href="/About"> About Us</a></li>
              <li><a href="/Contact">Contact Us</a> </li>
             </ul>
          </div>
        </div>
        <Form/>
      </div>
    </div>
    </>
  )
}

export default StockAudit