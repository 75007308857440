import React from 'react'
import './mycard.css'
import { Link } from 'react-router-dom'
const Mycard = (props) => {
  return (
    
   <Link to={props.href}  className='mycard'>
   <div >
      <div className="icon">
         <h2>{props.icon}</h2>
      </div>
      <div className="text">
      <a href={props.href}> <h3>{props.Servicename}</h3> </a>
        <p>{props.Description}</p>
      </div>
    </div>
   
   </Link>
  )
}
export default Mycard
