import React from 'react'
import './carousel.css'
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import HeaderImg from "./headerimg.jpg"
import { useState } from 'react';
function Carousel(props) {
  // const [showNavelinks,setShowNavelinks]= useState(false);
  const [showContent,setShowContent]= useState(false);
  const toogleContent =()=>{
    setShowContent(!showContent)
  }
  return (
    <div className={`Team ${showContent ? 'show-full-content' : ''}`}>
        <div className="img">
          <img src={props.img} alt="" />
        </div>
        <div className="name-location">
             <h3>{props.name}</h3>
             <p>{props.achievement}</p>
             <h4>{props.place}</h4>
        </div>
        <div className='line'></div>
        <div className="about">
        {showContent ? (
          <p>{props.About}</p>
        ) : (
          <p>{props.About.slice(0, 100)}...</p>
        )}
        {props.About.length > 100 && (
          <button className='show' onClick={toogleContent}>
            {showContent ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    </div>
    // <>
    //     <Carousel autoPlay interval="1000"transitionTime="1000" showThumbs={false}>
    //         <div className='Team'>
    //           <div className="img">
    //              <h2>{props.img}</h2>
    //           </div>
    //           <div className="name-location">
    //              <h3>{props.name}</h3>
    //              <h4>{props.place}</h4>
    //            </div>
    //            <div className='line'></div>
    //            <div className="about">
    //              <p>{props.About}</p>
    //            </div>
    //        </div>
    //     <div className='Team'>
    //      <div className="img">
    //        <h2>{props.img}</h2>
    //      </div>
    //      <div className="name-location">
    //          <h3>{props.name}</h3>
    //          <h4>{props.place}</h4>
    //      </div>
    //      <div className='line'></div>
    //      <div className="about">
    //         <p>{props.About}</p>
    //      </div>
    //     </div>
    //     </Carousel>
    // </>
  )
}

export default Carousel
