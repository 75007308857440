// import { formatMs } from '@material-ui/core'
import React from 'react'
import './Job.css'
const Job = (props)=> {
  return (
    <>
    <div className="Job">
        <h2>{props.name}</h2>
        <p>{props.Description}</p>
       
        <a href={props.form}>
            <button className='job'>Apply</button>
        </a>
    </div>
    </>
  )
}

export default Job