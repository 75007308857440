import Home from "./ssco/Home";
import './App.css'
function App() {
  return (
    <div className="App">
      <Home/>   
    </div>
  );
}

export default App;
